import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "notices"
};
import { router } from '@/router';
import { computed, ref } from 'vue';
import { getNotices } from '@/http';
import { useUserStore } from '@/stores/user';
export default {
  __name: 'notices',
  setup(__props) {
    const userStore = useUserStore();
    const lang = computed(() => userStore.lang);
    const titleKey = computed(() => {
      return {
        'en_US': 'name',
        'fr_FR': 'fr_FR_name',
        'es_ES': 'es_ES_name',
        'it_IT': 'it_IT_name'
      }[lang.value];
    });
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1);
    const back = () => {
      router.go(-1);
    };
    const jumpTo = (path, item) => {
      userStore.setNotice(item);
      router.push(path);
    };
    const onLoad = async () => {
      loading.value = true;
      getNotices({
        page: page.value,
        perPage: 10
      }).then(({
        data
      }) => {
        loading.value = false;
        if (data.current_page === 1) {
          list.value = data.data;
        } else {
          list.value = list.value.concat(data.data);
        }
        finished.value = data.current_page >= data.last_page;
        if (!finished.value) {
          page.value++;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('announcement'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": _ctx.$t('no_more'),
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createBlock(_component_van_cell, {
            title: item[titleKey.value],
            "is-link": "",
            key: item.id,
            onClick: $event => jumpTo('/noticeDetails', item)
          }, null, 8, ["title", "onClick"]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "finished-text"])])], 64);
    };
  }
};